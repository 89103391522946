import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react';
import CanvasJSReact from './canvasjs.react';
import Sidebar from './Sidebar'
import { API } from 'aws-amplify';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import axios from 'axios'
import Button from '@mui/material/Button';
import { ExportToCsv } from 'export-to-csv';
import TabletIcon from '@mui/icons-material/TabletMac';
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
//import './circular-progress-style.css';


var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function CircularProgressbarProcessor(props) {
  const device = props.device;

  const value = Math.floor(Math.random() * (2000 - 400 + 1) + 400);
  var textColor = "gray";
  var pathColor = "green";
  var trailColor = "green";
  
  if (value > 1500) {
    pathColor = "red";
  } else if (value > 1000) {
    const greenOffset = Math.floor((1 - ((value - 1000) / 500)) * 255);
    console.log("green offset: " + greenOffset);
    pathColor = 'rgba(255, ' + greenOffset + ', 51, 1)';
  } else if (value > 500) {
    const redOffset = Math.floor((((value - 500) / 500)) * 255);
    console.log("red offse: " + redOffset);
    pathColor = 'rgba(' + redOffset + ', 255, 51, 1)';
  }


  return (
    <CircularProgressbarWithChildren 
      value={2000} 
      maxValue={2000} 
      styles={buildStyles({
        textColor: {textColor},
        pathColor: pathColor,
        trailColor: "gold"
      })}>
      <TabletIcon />
      <div style={{ fontSize: 12, marginTop: 15 }}>
        <strong>{value}</strong>
      </div>
    </CircularProgressbarWithChildren>
  )
}

class Devices extends Component {
  state = {
    devices: [],
    deviceRawData: [],
    deviceData: {}
  }

  componentDidMount() {
    console.log(this.props.userinfo.attributes);
    const api = 'https://1yoow94nxl.execute-api.us-east-2.amazonaws.com/staging/orgs/data';
    const data = {
                    "organization": this.props.userinfo.attributes["custom:organization"],
                    "username": this.props.userinfo.attributes.email
                  };

    const options = {
      headers: {
        'x-api-key': 'wkmrmJFtGl7axkyx9yTWEfVIgAI9j7G1nDUqF6if',
        'Content-Type': 'application/json'
      }
    };
    console.log(data);

    axios
      .post(api, data, options)
      .then((response) => {
        console.log(response.data.body.devices[0].S);
        
        this.setState({ devices: response.data.body.devices });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getDeviceData(deviceName) {
    console.log("did click grid");
    console.log("device name: " + deviceName);
    const api = 'https://1yoow94nxl.execute-api.us-east-2.amazonaws.com/staging/devices/logs';
    const data = {
                    "device": deviceName
                  };

    const options = {
      headers: {
        'x-api-key': 'wkmrmJFtGl7axkyx9yTWEfVIgAI9j7G1nDUqF6if',
        'Content-Type': 'application/json'
      }
    };
    
    axios
      .post(api, data, options)
      .then((response) => {
        //console.log(response.data.body);
        const responseBody = JSON.parse(response.data.body);
        this.state.deviceRawData = responseBody.logs;

        responseBody.logs.sort(function(a, b) {
          var keyA = new Date(Number(a.timestamp));
          var keyB = new Date(Number(b.timestamp));
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
        
        var limit = 50000;
        var y = 100;    
        var y1 = 250;
        var data = [];
        var co2DataSeries = { type: "line", name: "co2", showInLegend: true};
        var temperatureDataSeries = { type: "line", name: "temperature", showInLegend: true, axisYType: "secondary" };
        var pressureDataSeries = { type: "line", name: "pressure", showInLegend: true };
        var humidityDataSeries = { type: "line", name: "humidity", showInLegend: true, axisYType: "secondary" };
        var co2DataPoints = [];
        var temperatureDataPoints = [];
        var pressureDataPoints = [];
        var humidityDataPoints = [];

        for (var i = 0; i < responseBody.count; i += 1) {
          const dataPoint = responseBody.logs[i];
          //console.log(JSON.stringify(dataPoint));
          co2DataPoints.push({
            x: new Date(Number(dataPoint.timestamp) * 1000),
            y: Number(dataPoint.co2)
          });
          temperatureDataPoints.push({
            x: new Date(Number(dataPoint.timestamp) * 1000),
            y: Number(dataPoint.temperature)
          });
          pressureDataPoints.push({
            x: new Date(Number(dataPoint.timestamp) * 1000),
            y: (Number(dataPoint.pressure) / 100)
          });
          humidityDataPoints.push({
            x: new Date(Number(dataPoint.timestamp) * 1000),
            y: Number(dataPoint.humidity)
          });
        }
        
        /*for (var i = 0; i < limit; i += 1) {
          y += Math.round(Math.random() * 10 - 5);
          dataPoints.push({
            x: i,
            y: y
          });
          y1 += Math.round(Math.random() * 10 - 5);
          dataPoints1.push({
            x: i,
            y: y1
          });
        }*/
        co2DataSeries.dataPoints = co2DataPoints;
        temperatureDataSeries.dataPoints = temperatureDataPoints;
        pressureDataSeries.dataPoints = pressureDataPoints;
        humidityDataSeries.dataPoints = humidityDataPoints;
        data.push(co2DataSeries);
        data.push(temperatureDataSeries);
        data.push(pressureDataSeries);
        data.push(humidityDataSeries);
        
        /*const spanStyle = {
          position:'absolute', 
          top: '10px',
          fontSize: '20px', 
          fontWeight: 'bold', 
          backgroundColor: '#d85757',
          padding: '0px 4px',
          color: '#ffffff'
        };*/
        
        const devicedata = {
          zoomEnabled: true,
          animationEnabled: true,
          axisY: {
            "title" : "CO2 / Pressure"
          },
          axisY2: {
            "title" : "Temperature / Humidity"
          },
          toolTip: {
            shared: true
          },
          title: {
            text: deviceName
          },
          data: data  // random data
        };
        console.log("setting state");
        console.log(JSON.toString(devicedata));
        this.setState({ deviceData: devicedata });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  downloadDatalog() {
    console.log("download device data button pressed");
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      //showTitle: true,
      //title: 'My Awesome CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      //headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
 
    const csvExporter = new ExportToCsv(options);
 
    csvExporter.generateCsv(this.state.deviceRawData);
  }

  render() {
    console.log("went to devices");
        
    return (
      <div className="App" id="outer-container">
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <Box sx={{ flexGrow: 1 }}  m={2} pt={3} alignItems='center'>
          <Grid container spacing={3}>
            {this.state.devices.map((device) => (
              <Grid item xs="auto" onClick={() => this.getDeviceData(device.S)} justifyContent='center'>
                <div style={{ width: 200, height: 200, marginTop: 15, marginBottom: 15 }}>
                  <CircularProgressbarProcessor device={device}/>
                </div>
                <Item>{device.S} </Item>
              </Grid>
            ))}
          </Grid>
        </Box>
        <CanvasJSChart options = {this.state.deviceData} 
                onRef={ref => this.chart = ref}
              />
              {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all                  chart properties and methods*/}
        { (() => {
          console.log("if device data show");
          console.log(this.state.deviceData);
          if (this.state.deviceData.data) {
            return (
              <Box textAlign='center'>
                <Button variant="contained" onClick={() => this.downloadDatalog()}>Download Datalog</Button>
              </Box>
            )
          }
        })()}
        
      </div>
    );
  };
}

export default Devices;