import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react';
import CanvasJSReact from './canvasjs.react';
import Sidebar from './Sidebar'
import Button from '@mui/material/Button';
import Devices from './Devices'
import Box from '@mui/material/Box';

import { Amplify } from 'aws-amplify';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';
Amplify.configure(awsExports);

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class Home extends Component {
  componentDidMount() {
    
  }

  logUserInfo(user) {
    console.log(user);
  }

  render() {
    
        
    return (
      <Authenticator>
      {({ signOut, user }) => (
        <main>
          <Box textAlign='center'>
            <h2>Hello {user.attributes.email}</h2>
            <Button variant="contained" onClick={signOut}>Sign out</Button>
          </Box>
          <div className="App" id="outer-container">
            <Devices userinfo = {user}/>      
          </div>
        </main>
      )}
      </Authenticator>
    );
  };
}

export default Home;