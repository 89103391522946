import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react';
import CanvasJSReact from './canvasjs.react';
import Sidebar from './Sidebar'

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class Devices extends Component {
  render() {
    console.log("went to users");
    var limit = 50000;
    var y = 100;    
    var data = [];
    var dataSeries = { type: "line" };
    var dataPoints = [];
    
    for (var i = 0; i < limit; i += 1) {
      y += Math.round(Math.random() * 10 - 5);
      dataPoints.push({
        x: i,
        y: y
      });
    }
    dataSeries.dataPoints = dataPoints;
    data.push(dataSeries);
    
    const spanStyle = {
      position:'absolute', 
      top: '10px',
      fontSize: '20px', 
      fontWeight: 'bold', 
      backgroundColor: '#d85757',
      padding: '0px 4px',
      color: '#ffffff'
    }
    
    const options = {
      zoomEnabled: true,
      animationEnabled: true,
      title: {
        text: "Users Line Graph"
      },
      data: data  // random data
    }
    
        
    return (
      <div className="App" id="outer-container">
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
          <CanvasJSChart options = {options} 
            onRef={ref => this.chart = ref}
          />
          {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}        
      </div>
    );
  };
}

export default Devices;