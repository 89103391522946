import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react';
import CanvasJSReact from './canvasjs.react';
import Sidebar from './Sidebar'
import Devices from './Devices'
import Users from './Users'
import Home from './Home'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";


//var CanvasJSReact = require('./canvasjs.react');
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


class App extends Component {
	componentDidMount() {
		
  }

  render() {
    return (
      <Router>
        <div>
          <h1 style={{textAlign: "center"}} className="font-link">
              CO2Meter IoT
          </h1>

          {/*
            A <Switch> looks through all its children <Route>
            elements and renders the first one whose path
            matches the current URL. Use a <Switch> any time
            you have multiple routes, but you want only one
            of them to render at a time
          */}
          <Routes>
            <Route exact path="/" element={<Home />}>
              
            </Route>
            <Route path="/devices" element={<Devices />}>
              
            </Route>
            <Route path="/users" element={<Users />}>
              
            </Route>
            {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </div>
      </Router>
    );  
  }		
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;

